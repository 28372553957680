<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Mapa de Ocupação</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-menu :offset-y="true">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip top>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn color="primary" outlined icon large v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                <v-icon>
                  {{ icons.mdiTools }}
                </v-icon>
              </v-btn>
            </template>
            <span>Ferramentas</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item @click="sendExportExcel()">
            <v-list-item-title>
              <v-icon>{{ icons.mdiFileExcelOutline }}</v-icon> Exportar Excel
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <a :href="newTab" target="_blank" v-bind="attrs" v-on="on">
            <v-btn class="ml-2" icon large outlined color="primary">
              <v-icon>{{ icons.mdiOpenInNew }}</v-icon>
            </v-btn>
          </a>
        </template>
        <span>Nova Aba</span>
      </v-tooltip>
      <v-badge :content="bagFilter" :value="bagFilter" bordered color="error" overlap>
        <v-btn class="ml-2" rounded outlined large color="primary" @click="dialogFilter = true">
          <v-icon>{{ icons.mdiFilter }}</v-icon>Filtros
        </v-btn>
      </v-badge>
      <v-spacer></v-spacer>
    </v-toolbar>
    <div class="m-2">
      <v-data-table :loading="loadingTable" loading-text="Carregando..." disable-pagination disable-sort
        hide-default-footer fix-header :headers="headersSummary" :items="summary"
        class="elevation-1 summary-occupation-map">
        <template v-slot:no-data>
          <NoDataTable></NoDataTable>
        </template>
        <template v-slot:foot>
          <tfoot>
            <tr style="height:44px; background:#fafafa">
              <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">TOTAL</td>
              <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"
                v-for="item in totalSummary"><b> {{ item }} </b></td>
            </tr>
            <tr style="height:44px; background:#fafafa">
              <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">Ocupações</td>
              <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">%</td>
              <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"
                v-for="item in percentOccupationSummary"><b> {{ parseFloat(item).toFixed(2) }}% </b></td>
            </tr>
          </tfoot>
        </template>
      </v-data-table>
    </div>
    <div class="m-2">
      <v-data-table :loading="loadingTable" loading-text="Carregando..." disable-pagination disable-sort
        hide-default-footer fixed-header :headers="headersDetailed" :items="detailed" :height="'30em'"
        class="elevation-1 occupations-map">

        <template v-slot:item.status="{ item, header }">
          <div class="occupations-map-status">
            <v-tooltip top
              v-if="item[header.text].all_day && item[header.text].all_day.status == 'ocupado'">
              <template v-slot:activator="{ on, attrs }">
                <span :class="{
                  'map-status w-full': true,
                  ...getStatusBindClass(item[header.text].all_day)
                }" v-bind="attrs" v-on="on"></span>
              </template>
              <span class="d-flex flex-column">
                <span>Reseva ID: {{ item[header.text].all_day.reserve_id }}</span>
                <span>Hóspede: {{ item[header.text].all_day.guest.name }}</span>
              </span>
            </v-tooltip>
            <v-tooltip top
              v-if="!item[header.text].all_day && item[header.text].expected_end && item[header.text].expected_end.status == 'ocupado'">
              <template v-slot:activator="{ on, attrs }">
                <span :class="{
                  'map-status map-status-morning-turn': true,
                  ...getStatusBindClass(item[header.text].expected_end)
                }" v-bind="attrs" v-on="on"></span>
              </template>
              <span class="d-flex flex-column">
                <span>Reseva ID: {{ item[header.text].expected_end.reserve_id }}</span>
                <span>Hóspede: {{ item[header.text].expected_end.guest.name }}</span>
              </span>
            </v-tooltip>
            <v-tooltip top
              v-if="!item[header.text].all_day && item[header.text].started && item[header.text].started.status == 'ocupado'">
              <template v-slot:activator="{ on, attrs }">
                <span :class="{
                  'map-status map-status-evening-turn': true,
                  ...getStatusBindClass(item[header.text].started)
                }" v-bind="attrs" v-on="on"></span>
              </template>
              <span class="d-flex flex-column">
                <span>Reseva ID: {{ item[header.text].started.reserve_id }}</span>
                <span>Hóspede: {{ item[header.text].started.guest.name }}</span>
              </span>
            </v-tooltip>

            <span v-if="item[header.text].all_day && item[header.text].all_day.status != 'ocupado'"
              :class="{
                'map-status w-full': true,
                ...getStatusBindClass(item[header.text].all_day)
              }">
            </span>
            <span
              v-if="!item[header.text].all_day && item[header.text].expected_end && item[header.text].expected_end.status != 'ocupado'"
              :class="{
                'map-status map-status-morning-turn': true,
                ...getStatusBindClass(item[header.text].expected_end)
              }">
            </span>
            <span
              v-if="!item[header.text].all_day && item[header.text].started && item[header.text].started.status != 'ocupado'"
              :class="{
                'map-status map-status-evening-turn': true,
                ...getStatusBindClass(item[header.text].started)
              }">
            </span>
          </div>
        </template>

        <template v-slot:no-data>
          <NoDataTable></NoDataTable>
        </template>
      </v-data-table>

    </div>

    <!-- DIALOG FILTRO -->
    <v-dialog v-model="dialogFilter" width="700">
      <v-card>
        <BarTitleDialog title="FILTROS" @close="dialogFilter = false" />
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="date_start" :min="date_now" :max="date_end" hide-details outlined type="date"
                label="Data Inicial"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="date_end" :min="date_start" hide-details outlined type="date"
                label="Data Final"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col class="text-right">
              <v-btn text color="secondary" x-large @click="dialogFilter = false">
                FECHAR
              </v-btn>
              <v-btn color="primary" class="ml-2" x-large @click="confirmFilter()">
                aplicar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import NoDataTable from '@/components/NoDataTable.vue';
import {
  mdiClose,
  mdiDeleteOutline,
  mdiFileExcelOutline,
  mdiFilter,
  mdiOpenInNew,
  mdiPencilOutline,
  mdiPlus,
  mdiTools,
} from '@mdi/js';
import { mapActions } from 'vuex';

import BarTitleDialog from '@/components/BarTitleDialog.vue';

export default {
  components: {
    NoDataTable,
    BarTitleDialog,
  },
  data: () => ({
    dialogFilter: false,
    date_start: null,
    date_end: null,
    categoryItems: [],
    loadingTable: true,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    dialogDelete: false,
    dialog: false,
    headersDetailed: [
      {
        text: 'CATEGORIA',
        value: 'category',
      },
      {
        text: 'QUARTO',
        value: 'room',
      },
    ],
    headersSummary: [
      {
        text: 'CATEGORIA',
        value: 'category',
      },
      {
        text: 'QUARTOS',
        value: 'rooms',
      },
    ],
    detailed: [],
    summary: [],
    totalSummary: {},
    percentOccupationSummary: {},
    options: {},
    icons: {
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiClose,
      mdiOpenInNew,
      mdiTools,
      mdiFileExcelOutline,
      mdiFilter,
    },
  }),

  computed: {
    newTab() {
      let urlAtual = window.location.href
      urlAtual = urlAtual.replace('#', '')

      return `${urlAtual}_blank`
    },
    bagFilter() {
      return Object.keys({ date_start: this.date_start, date_end: this.date_end }).length
    },
    date_now() {
      return new Date(Date.now()).toISOString().split('T')[0];
    }
  },
  watch: {},

  created() {
    this.initialize()
  },

  methods: {
    ...mapActions('occupation_map', ['getAll', 'exportExcel']),
    initialize() {
      this.loadingTable = true
      this.headersDetailed = [
        {
          text: 'CATEGORIA',
          value: 'category',
        },
        {
          text: 'QUARTO',
          value: 'room',
        },
      ]
      this.headersSummary = [
        {
          text: 'CATEGORIA',
          value: 'category',
        },
        {
          text: 'QUARTOS',
          value: 'rooms',
        },
      ]
      if (this.date_start == null || this.date_end == null) {
        let now = new Date(Date.now())
        this.date_start = now.toISOString().split('T')[0]
        now.setMonth(now.getMonth() + 1)
        now.setDate(now.getDate() - 1)
        this.date_end = now.toISOString().split('T')[0]
      }

      const endDate = new Date(this.date_end)
      const currentDate = new Date(this.date_start)
      const dateFormat = new Intl.DateTimeFormat('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      })
      while (currentDate <= endDate) {
        currentDate.setDate(currentDate.getDate() + 1)
        this.headersDetailed.push({
          text: dateFormat.format(currentDate),
          value: 'status',
        })
        this.headersSummary.push({
          text: dateFormat.format(currentDate),
          value: dateFormat.format(currentDate),
        })
      }

      this.totalSummary = {}
      this.percentOccupationSummary = {}

      this.getAll({ date_start: this.date_start, date_end: this.date_end })
        .then(response => {
          if (response.data.success) {
            this.detailed = response.data.data.detailed.map(r => {
              let data = {
                room: r.room,
                category: r.category,
                status_by_day: r.status_by_day,
              }

              r.status_by_day.map(e => {
                const currentDate = new Date(e.date)
                currentDate.setDate(currentDate.getDate() + 1)
                data = { [dateFormat.format(currentDate)]: e, ...data }
              })
              return data
            })
            this.totalSummary = {}
            this.summary = response.data.data.summary.map(r => {
              let data = {
                rooms: r.rooms,
                category: r.category,
                occupations_by_day: r.occupations_by_day,
              }
              this.totalSummary['rooms'] = (this.totalSummary['rooms'] ?? 0) + r.rooms
              for (const key in r.occupations_by_day) {
                const currentDate = new Date(key)
                currentDate.setDate(currentDate.getDate() + 1)
                data = { [dateFormat.format(currentDate)]: r.occupations_by_day[key], ...data }
                this.totalSummary[dateFormat.format(currentDate)] =
                  (this.totalSummary[dateFormat.format(currentDate)] ?? 0) + r.occupations_by_day[key]

                this.percentOccupationSummary[dateFormat.format(currentDate)] =
                  (this.totalSummary[dateFormat.format(currentDate)] * 100) / this.totalSummary['rooms']
              }
              return data
            })
          }
          this.loadingTable = false
        })
        .finally(() => {
          this.loadingTable = false
        })
    },
    getStatusBindClass(shift_status) {
      return {
        'map-status-ocupado': shift_status && shift_status.status == 'ocupado',
        'map-status-manutencao': shift_status && shift_status.status == 'manutencao',
        'map-status-stand-by': shift_status && shift_status.status == 'stand-by',
        'map-status-reservado': shift_status && shift_status.status == 'reservado'
      };
    },
    confirmFilter() {
      this.initialize()
      this.dialogFilter = false
    },
    sendExportExcel() {
      this.exportExcel({ date_start: this.date_start, date_end: this.date_end })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'Resumo Mapa de Ocupações.xlsx')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => { })
    },
  },
}
</script>
<style scoped>
.summary-occupation-map {

  thead>tr>th,
  tbody>tr>td,
  tfoot>tr>td {
    min-width: 4rem !important;
    width: 4rem !important;
  }

  thead>tr>th:first-child,
  tbody>tr>td:first-child,
  tfoot>tr>td:first-child {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 1;
    background: white;
  }

  thead>tr>th:nth-child(2),
  tbody>tr>td:nth-child(2),
  tfoot>tr>td:nth-child(2) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 6rem;
    z-index: 1;
    background: white;
  }

  thead>tr>th:nth-child(2),
  tbody>tr>td:nth-child(2),
  tfoot>tr>td:nth-child(2),
  thead>tr>th:first-child,
  tbody>tr>td:first-child,
  tfoot>tr>td:first-child {
    min-width: 6rem !important;
    width: 6rem !important;
    max-width: 6rem !important;
  }
}

.occupations-map {

  thead>tr>th,
  tbody>tr>td {
    min-width: 3rem !important;
    width: 3rem !important;
  }

  thead>tr>th:first-child,
  tbody>tr>td:first-child {
    left: 0;
  }

  thead>tr>th:nth-child(2),
  tbody>tr>td:nth-child(2) {
    left: 6rem;
  }

  thead>tr>th:nth-child(2),
  thead>tr>th:first-child {
    z-index: 3 !important;
  }

  tbody>tr>td:nth-child(2),
  tbody>tr>td:first-child {
    z-index: 2 !important;
  }

  thead>tr>th:nth-child(2),
  tbody>tr>td:nth-child(2),
  thead>tr>th:first-child,
  tbody>tr>td:first-child {
    position: sticky !important;
    position: -webkit-sticky !important;
    background: white;
    min-width: 6rem !important;
    width: 6rem !important;
    max-width: 6rem !important;
  }

  tbody {
    td:has(.occupations-map-status) {
      margin: 0 !important;
      padding: 0 !important;

      .occupations-map-status {
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;

        .map-status {
          margin: 0.5rem 0;
        }

        .map-status-ocupado,
        .map-status-ocupado::after,
        .map-status-ocupado::before {
          background-color: #eb5c0c !important;
        }

        .map-status-reservado,
        .map-status-reservado::after,
        .map-status-reservado::before {
          background-color: #a780ff !important;
        }

        .map-status-manutencao,
        .map-status-manutencao::after,
        .map-status-manutencao::before {
          background-color: black !important;
        }

        .map-status-stand-by,
        .map-status-stand-by::after,
        .map-status-stand-by::before {
          background-color: #0050ff !important;
        }

        .map-status-evening-turn,
        .map-status-morning-turn {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 65%;
        }

        .map-status-evening-turn {
          right: 0;
          clip-path: polygon(55% 0%, 100% 0%, 100% 100%, 0% 100%);
        }

        .map-status-morning-turn {
          left: 0;
          clip-path: polygon(0 0, 100% 0, 100% 0, 45% 100%, 0 100%);
        }
      }
    }
  }
}
</style>
